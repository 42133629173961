.advisor-wrapper {
    display: flex;
    height: 100%;
    min-height: calc(100vh - 160px);
    margin-right: 0.3em;
}

.advisor-menu-wrapper {
 
    background-color: #F2F3F5; /* Lighter shade for a cleaner look */
    padding-top: 1rem;
    width: 280px;
    transition: width 0.3s; /* Smooth transition for expanding/collapsing */
 }

.advisor-menu-wrapper:has(.closed) {
    width: unset; /* Define a specific width for the collapsed state */
}


/* Improved menu item styles */
.menu-item-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
     text-align: left;
    color: #5E5E5E;
    padding: 0.3rem 0rem  1rem  0.5rem;
    padding-top: 8px;
    transition: all 0.2s ease-in-out;
    position: relative; /* For pseudo-element positioning */
    
}
 
 
.menu-item-wrapper::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
 
 }

.menu-item-wrapper:hover::before {
    background: #86BC25 /* Color accent on hover */
}

.menu-item-wrapper:hover {
      cursor: pointer;
 }

.menu-item-title {
    display: flex;
    align-items: center; /* Align icon and text */
    padding: 0.1rem 0.75rem;
    width: 100%;
    transition: background-color 0.2s; /* Smooth background transition */
}   

.menu-item-title.selected {
    background-color: white;
    border-top-left-radius: 30px; /* Less exaggerated radius */
    border-bottom-left-radius: 30px;
 }
 .menu-item-icon {
    padding-right: 0.5rem;
    width: 28px;
    height: 28px;
}


/* Custom scrollbar */
.advisor-menu-wrapper::-webkit-scrollbar {
    width: 8px;
}

.advisor-menu-wrapper::-webkit-scrollbar-track {
    background: #e2e2e2;
}

.advisor-menu-wrapper::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 4px;
}

.advisor-menu-wrapper::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .advisor-menu-wrapper {
        width: 80px; /* Smaller menu for tablet and below */
    }

    .menu-item-wrapper {
        font-size: 18px; /* Smaller font size for tablet and below */
    }
}

/* Accessibility improvements for keyboard navigation */
.menu-item-wrapper:focus {
    outline: none;
    background-color: #e9ecef;
    color: #333;
}

.menu-item-wrapper:focus::before {
    background: #4CAF50; /* Color accent for focused item */
}
.advisor-menu-button.closed img{
    rotate: 90deg;
}

.next-section-button-wrapper{
    display: flex;
    justify-content: end;
    padding-right: 10%;
}

.next-section-button{
    background-color: #86BC25;
    color: white;
    border-radius: 25px;
    padding:0.15rem 1.75rem;
    border:none;
}
