tr.selectable:hover{
  cursor: pointer;
}

.table-header {
    background: #43b02a;
    color: white;
    font-weight: bold;
    border: 1px solid #000;
    padding: 8px;
  }
  
  .table-cell {
    background: white;
    color: black;
    border: 1px solid #000;
    padding: 8px;
  }

  .editable-table {
    border-collapse: collapse; 
    width: 100%;
  }
  
.form-control {
    border: none !important;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }