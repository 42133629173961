
.chat-container {
}

 
  .chat-window {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .message-list {
    padding: 10px;
    max-height: 500px;
    min-height: 500px;
    overflow-y: scroll;
  }
  
  .message {
    background-color: #f5f5f5;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
  }
  
  .dark {
    background-color: #c5c5c5;
  }

  .user {
    font-weight: bold;
  }
  
  .input-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  input[type="text"] {
    flex: 1;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  
.chat-list {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top:10px;
  padding:4px;
}

.chat-item {
  background-color: #f5f5f5;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.chat-item-title {
  
}

.chat-item-date {
  font-weight: bold;
  font: size 10px;
}