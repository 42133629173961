/* TechnologyStack.css */
.technology-stack {
    border-radius: 8px;
    padding: 20px;
    margin: auto;
  }
  
  .technology-stack h2 {
    color: #02351C;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .stack-section {
    margin-bottom: 20px;
  }
  
  .stack-section h3 {
    color: #86BC25;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .stack-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .stack-item p {
    margin: 0;
    font-weight: 500;
  }
  
  .stack-item span {
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 4px;
  }
  
  .fully-supported {
    color: #4CAF50; /* Green for fully supported */
  }
  
  .out-of-support {
    color: #F44336; /* Red for out of support */
  }
  
  /* Additional styling for different levels of support could be added here */
