.inline {
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
}

.download-btn,
.addReq-btn {
    background-color: #26890d !important;
    border-color: #26890d !important;
    font-weight: bold !important;
    transition: none !important;
    border-radius: unset !important;
}

.download-btn:hover,
.addReq-btn:hover {
    background-color: #43b02a !important;
    border-color: #43b02a !important;
    color: #000 !important;
}

.download-btn:hover i,
.addReq-btn:hover i {
    color: #000 !important;
}

.action-fields-header {
    min-width:60px;
}