.general-information-container {
  border-radius: 8px;
  padding: 20px;
  width: 100%;
}

.general-information-title {
  color: #02351C;
  /* Updated to the green shade */
  margin-bottom: 20px;
  font-weight: bold;
  /* Title bold */
  display: flex;
}

.information-item {
  display: flex;
  padding: 0 20px;
  /* Add padding to align with container's padding */
}

.item-title {
  color: #86BC25;
  font-size: 14px;
  font-weight: bold;
  /* Labels bold */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 170px;
  /* Right align the titles */
  /* Allows the title to fill the space */
}

.item-value {
  font-size: 14px;
  margin-left: 10px;
  /* Space between label and value */
  /* Adjusts the space for value, ensuring alignment */
  display: flex;
  align-items: center;
  margin-top: 4px;
}