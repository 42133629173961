.summary-cards-wrapper{
    display: flex;
    gap:0.2rem;
}

.application-card{
    width: 100%;
    background-color: aqua;
    padding:0.1rem 4rem;
}

.single-card-description{
    min-height:200px;
}