.download-resume-wrapper{
    margin:auto;
    width:80%;
    display: flex;
    justify-content: center;
}
.download-resume-button{
    background-color: #86BC25;
    color:white;
    border-radius: 25px;
    border:none;
    padding:0.15rem 1.75rem;
}