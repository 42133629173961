.container {
    margin-top: 25px;
    margin-bottom: 60px;
}

.icon-link {
    text-decoration: none;
    color: black;
    margin-left: 10px;
}

.icon-link:hover {
    color: #26890d;
}

.form-control:focus,
.form-select:focus {
    border-color: #26890d !important;
    box-shadow: 0 0 0 0.25rem rgba(38, 137, 13, .25) !important;
}
 .saveBtn {
    background-color: #26890d !important;
    border-color: #26890d !important;
    font-weight: bold !important;
    transition: none !important;
    border-radius: unset !important;
     
}
.cancelButton {
    background-color: #aeaeae !important;
    border-color: #aeaeae !important;
    font-weight: bold !important;
    transition: none !important;
    border-radius: unset !important;
}

 .saveBtn:hover {
    background-color: #43b02a !important;
    border-color: #43b02a !important;
    color: #000 !important;
}

.form-label {
    font-weight: bold;
    margin-top: 6px;
}

.chatButton {
    position:fixed;
    bottom:60px;
    right: 20px;
    border-radius: 50% !important;
    height:60px;
    width:60px;
}

.chatButton i {
    font-size: 30px;
}

.improveButCont{
    display: flex;
    justify-content: space-between;
}

 .testbtn{
    display: flex;
    justify-content: flex-end;

    align-items:flex-end;
 }