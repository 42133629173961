.upload-form-title-wrapper1{
    border-radius: 33px 33px 0px 0px;
    background-color: #D9D9D9;
    padding: 1rem 2rem;
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}
.form-text-area {
    margin: 10px 0; /* Vertical spacing around text area */
}

 
textarea {
    width: 100%; /* Full width to match the parent's width */
    border: 1px solid #ccc; /* Consistent border styling */
    border-radius: 4px; /* Rounded corners for the textarea */
    padding: 8px; /* Padding inside the textarea */
    box-sizing: border-box; /* Border box to include padding and border in the width */
    resize: vertical; /* Allows the user to resize the textarea vertically */
}

.body-wrapper {
    background-color: #F5F5F5;
    border-radius: 0px 0px 33px 33px;
    padding: 1rem 2rem;
 
 
    text-align: left;

}

 
.form-text-area {
    width: 100%;
}

.form-text-area textarea {
    width: 100%;
    height: 30rem;
    font-size:16px;
}



.body-wrapper.full {
    border-radius: 33px;
}

.btn-pos {
    display: flex; /* Flex display for positioning buttons */
    justify-content: flex-end; /* Align buttons to the right */
    margin-top: 10px; /* Space above the buttons */
}

.save-btn {
    cursor: pointer; /* Cursor pointer to indicate button */
    padding: 6px 12px; /* Padding inside the button */
    border: none; /* Remove default border */
    background-color: #86BC25; /* Green background for the save button */
    margin-right: 10px;
    border-radius: 4px; /* Rounded corners for the button */
    color: #fff; /* White text color */
}

 
 
.save-btn:hover, .edit-btn:hover {
    opacity: 0.9; /* Slight opacity change on hover for feedback */
}

 