 
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color:#02351C;
     width: 100%;
  }
   
  

  .columns {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .column {
    background-color: #f0f0f0; /* Adjust color based on your design */
    padding: 20px;
    border-radius: 10px; /* This gives rounded corners */
     
    
  }
 
  
  .column h3 {
    margin-bottom: 10px;
    text-align: center;
}
  
  .column ul {
    list-style: none;
    padding: 0;
    
    
  }
  
  .column li {
    display: flex;
    font-weight: bold;
    justify-content: center;
    background-color: #D9D9D9;
     padding: 10px;
    }

 