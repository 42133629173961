.project-info-text {
  width: 600px !important;
  margin-top: -4px !important;
}

.project-info-text:hover {
  border-color: green;
}

.cancel-btn {
  margin-left: 30px;
  background-color: white !important;
  color: green !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.cancel-btn:hover {
  background-color: lightgrey !important;
}

.create-btn {
  margin-left: 10px;
  background-color: green !important;
  border-color: green !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.create-btn:hover {
  background-color: green !important;
  opacity: 85%;
}

.createProjectForm {
  margin-left: 15px;
}

.newProjectHr {
  width: 600px;
}

.newProject {
  margin-top: 10px;
  font-size: 27px;
  margin-left: 50px;
  width: 600px;
}
