
.new-project-link{
    color:white;
    text-decoration: none;
 }
 .new-project-link:hover{
    color:black;
 }

.create-project-button{
    background-color: #26890d !important;
    border-color: #26890d !important;
    border-radius: 10px !important;
 }
 .create-project-button:hover{
    opacity: 85%;
 }

 .editStyle{
   color: black;
 }

.link-style:active{
    color: blue;
    text-decoration: underline;
}

.configButtons{
   cursor: pointer;
}

.editButton:hover{
   background-color: #26890d ;
}

.deleteButton:hover{
   background-color: red;
}

 .tableRows:hover .tName{
   cursor: pointer;
   text-decoration: underline;
   color: #26890d;
 }

 .deleteConfirm{
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .confirmationContent {
   text-align: center;
 }

 .projectName{
   color: #26890d;
 }

 .cancel-btn{
  margin-left: 30px;
  background-color: white !important;
  color: green !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .cancel-btn:hover{
      background-color: lightgrey !important;
  }