.messageArea {
  white-space: normal; /* This is usually the default, so this line might not be necessary. */
  /* other styles */
}

.header-container{
  display: flex;
  flex-direction: row;


}
.header-text{
  display: flex;
  flex-direction: column;
  padding-left:  1rem;
  }

 
.commentIcon .fas {
  font-size: 44px; /* Or any size you want */
  color: white;
  width: 4rem;
  padding : 0.46rem;
  height: 4rem;
  border-radius: 50%;
 background-color: #007680;
}

.chat-window {
  position: fixed;
  bottom: 70px; /* Height of the toggle button plus some margin */
  right: 7rem;
  width: 31rem/* Set the width of the chat window */ !important;
  height: 35rem; /* Set the max height of the chat window */
  overflow: auto; /* Enable scrolling if content overflows */
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1040; /* Below the toggle button, but above other elements */
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.chat-close-button {
  align-self: flex-end;
  margin-top: 10px;
}

.chatButton1 {
  position:fixed;
  bottom:60px;
  right: 20px;
  border-radius: 50% !important;
  background-color: #007680 !important;
}

.clockBtn{
  width: 4.4rem;
   margin-left: 11rem;
    background-color: #007680;
 
     border: none; /* Remove border */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow */
 }
 .clockBtn:hover{
  opacity: 0.8;

}