.buttons-selection-wrapper{
    display: flex;
    gap:4px;

}

.button-selection{
    background-color: gray;
    padding:0.5rem 1.5rem;
    width:100%;
}

.button-selection:hover{
    cursor: pointer;
}

.button-selection.active{
    background-color: green;
}