.rejectButton{
     background-color: white !important;
    color: #26890d !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.rejectButton:hover{
    background-color: lightgrey !important;
}

.confirmButton{

    background-color: #26890d !important;
    border-color: black !important;

    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);


}


.confirmButton:hover{
    background-color: #43b02a !important;
     color: #000 !important;
}