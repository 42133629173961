.benchmark-wrapper{
    display: flex;
    justify-content: center;
    gap:5rem;
}

.gray-bg{
    background-color: #D2D2D2;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    text-align: center;
    min-width: 200px;
    font-weight: 700;
}

.black-bg{
    font-weight: 600;
    color:white;
    background-color: black;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    min-width: 200px;
    text-align: center;
}