.upload-form-wrapper {
    width: 85%;
    padding-top: 2rem;
    min-height: 120px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.upload-form-title-wrapper {
    border-radius: 33px 33px 0px 0px;
    background-color: #D9D9D9;
    padding: 1rem 2rem;
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.upload-form-body-wrapper {
    font-size: 11px;
    font-style: italic;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    background-color: #F5F5F5;
    border-radius: 0px 0px 33px 33px;
    padding: 1rem 2rem;
}

.upload-form-button-wrapper {
    display: flex;
    gap: 1.8rem;
}

.upload-form-button {
    border-radius: 25px;
    color: white;
    background-color: #046A38;
    align-self: center;
    padding: 0.25rem 1.2rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    background-color: #007680;
}

.upload-form-button.disabled {
    color: #53565A;
    background-color: #97999B;
    border: none;
}

.uploaded-files {
    display: flex;
    gap: 0.3rem;
}

.uploaded-file-pill {
    background-color: #43B02A;
    border-radius: 33px;
    color: white;
    padding: 0.2rem 0.8rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.pill-button {
    padding-left: 0.4rem;
}

.pill-button:hover {
    cursor: pointer;
}

.download-form-button {
    background-color: #86BC25;
    border-radius: 33px;
    color: white;
    padding: 0.2rem 0.8rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;

}

/*  */
/*  */
.upload-form-summary {
    border-right-style: groove;
    border-right-width: 3px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 2rem;
}

.download-form-button-wrapper {
    display: flex;
    margin: auto;
}

.upload-input {
    display: none
}

.pointer:hover {
    cursor: pointer;
}

/* .summary-button{
    color:white;
    background-color: #005A5C;
    border-radius: 12px;
    align-self: center;
    padding: 8px;
} */