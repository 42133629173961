.tabs-wrapper{
    display: flex;
}

.content-wrapper{
    width: 90%;
}

.tabs-icons-wrapper{
    align-self: center;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.single-icon{
    border-style: solid;
    border-width: 2px;
    padding:4px;
    border-radius: 45px;
    background-color: #D2D2D2;
    border-color: #D2D2D2
}
.single-icon.active{
    background-color: yellow;
    border-color: green;
}
.single-icon:hover{
    cursor: pointer;
}