:root {
    --background-modal-color: #86BC25;
    --color-timeline-default: #ffffff;
    --color-step-completed: #5C6174;
    --color-checkmark-completed: #ffffff;
    --color-in-progress: #26890d;
    --color-label-default: var(--color-timeline-default);
    --color-label-completed: var(--color-step-completed);
    --color-label-loading: var(--color-in-progress);
    --color-icon-completed: var(--color-step-completed);
    --color-icon-default: var(--color-timeline-default);
}

* {
    box-sizing: border-box;
}

.wrapper {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 200ms ease;
    background: var(--background-modal-color);
    height: 140px;
    max-width: 100%;
    flex-grow: 1;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14);
}

.main-container .steps-container {
    padding: 40px 200px 40px 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 992px) {
        padding: 40px;
    }
}

.main-container .steps-container .step {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease;
    flex-grow: 0;
    height: 15px;
    width: 15px;
    border: 4px solid var(--color-timeline-default);
    border-radius: 50%;
}

.main-container .steps-container .step .preloader,
body .main-container .steps-container .step svg {
    display: none;
}

.main-container .steps-container .step.completed {
    width: 18px;
    height: 18px;
    background: var(--color-step-completed);
    border: none;
}

.main-container .steps-container .step.completed svg {
    transition: all 200ms ease;
    display: block;
    height: 10px;
    width: 10px;
    fill: var(--color-checkmark-completed);
}

.main-container .steps-container .step.in-progress {
    /* width: 18px;
    height: 18px; */
    background: var(--color-in-progress);
    border: 4px solid var(--color-in-progress);
}

.main-container .steps-container .step .label {
    position: absolute;
    top: 20px;
    filter: none;
    z-index: 2000;
    color: var(--color-label-default);
    transition: all 200ms ease;
    font-weight: 700;
    text-align: center;
    line-height: 1;
}

.main-container .steps-container .step .label.completed {
    color: var(--color-label-completed);
}

body .main-container .steps-container .step .label.loading {
    color: var(--color-label-loading);
}

.main-container .steps-container .step .icon {
    font-size: 40px;
    position: absolute;
    top: -60px;
    color: var(--color-icon-default);
    transition: color 200ms ease;
}

.main-container .steps-container .step .icon.completed {
    color: var(--color-icon-completed);
}

.main-container .steps-container .step .icon.in-progress {
    color: var(--color-in-progress);
}

.main-container .steps-container .line {
    transition: all 200ms ease;
    height: 2px;
    flex-grow: 1;
    max-width: 100%;
    background: var(--color-timeline-default);
}

.main-container .steps-container .line.completed {
    background: var(--color-step-completed);
}

.main-container .steps-container .line.next-step-uncomplete {
    background: linear-gradient(to right, var(--color-step-completed), var(--color-timeline-default));
}

.main-container .steps-container .line.next-step-in-progress {
    background: linear-gradient(to right, var(--color-step-completed), var(--color-in-progress));
}

.main-container .steps-container .line.prev-step-in-progress {
    background: linear-gradient(to right, var(--color-in-progress), var(--color-timeline-default));
}