.fault-tolerance-analysis1 {
    font-family: 'Arial', sans-serif;
    background: #F5F5F5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 1.5rem 2rem;
  }
  
  .fault-tolerance-analysis1 h1 {
    color: #333 !important;
    font-size: 24px !important;
    margin-bottom: 10px !important;
    color: #02351C !important;
    font-weight: 800 !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    padding-left: 0px !important;
  }
  
  .fault-tolerance-analysis1 p {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
  
  .fault-tolerance-analysis1 table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 35px;
  }
  
  .fault-tolerance-analysis1 th,
  .fault-tolerance-analysis1 td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .fault-tolerance-analysis1 td {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #637381;
  }
  
  .fault-tolerance-analysis1 th {
    background-color: #F9FAFB;
  }
  
  .fault-tolerance-analysis1 footer {
    background-color: #86BC254D; /* Semi-transparent green background */
    border-radius: 20px;
    padding: 20px; /* Increased padding for better spacing */
    margin-top: 30px; /* Increased margin to separate from the content above */
    display: flex; /* Use flexbox for better alignment of child components */
    justify-content: center; /* Center-align the child components */
    align-items: center; /* Vertically center-align the child components */
    flex-direction: column; /* Stack child components vertically */
  }
  
  .tablegraphContainer {
    display: flex;
    /* Use flexbox to place children side by side */
    justify-content: space-between;
    /* Add space between the table and the graph */
    align-items: flex-start;
    /* Align items to the start of the container */
    gap: 20px;
    /* Optional: adds space between table and graph */
    background-color: white;
  }
  
  .tablegraphContainer>table {
    flex: 1;
    /* Allows the table to grow and fill the space */
    /* Adds margin to the right of the table */
  }
  
  .graph-container {
    flex: 1;
    /* Allows the graph container to grow and fill the space */
    max-width: 100%;
    /* Limits the width of the graph to half of the container */
  }
  
  .graph-image {
    width: 100%;
    min-height: 350px;
    /* Makes the graph image responsive within its container */
    border-radius: 8px;
    /* Optional: Adds rounded corners to the image */
  }
  
  
  
  
  .fault-tolerance-analysis footer {
    width: 90%;
    background-color: #86BC254D;
      padding: 10px 20px;
    margin-top: 20px;
    }
  
    .fault-tolerance-analysis .commentIcon {
      display: flex; /* Use flexbox for layout */
      align-items: center; /* Vertically center-align the icon and text */
      justify-content:center; /* Center-align content horizontally */
      margin-bottom: 10px; /* Space below the icon/text before the message component */
    }
    
    
  .pill {
    display: inline-flex;
    align-items: center;
    background-color: #00000080;
    /* Dark background for the pill */
    color: white;
    /* White text color */
    border-radius: 20px;
    /* Rounded corners */
    overflow: hidden;
    /* Ensures the child elements do not spill out of the rounded corners */
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  
  .pill-title,
  .pill-content {
    padding: 5px 15px;
    /* Padding inside the pill segments */
    font-size: 0.9rem;
    /* Smaller font size for the text inside the pill */
  }
  
  .pill-title {
    background-color: #00000080;
    /* Slightly lighter background for the title segment */
    border-right: 1px solid black;
    /* Separator between the title and content segments */
  }
  
  .pill-content {
    font-weight: bold;
    /* Bold for the content to make it stand out */
  }
  

  .btns-vertical{
 display: flex;
  }
  
   