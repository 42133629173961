.navigation-buttons-wrapper{
    display: flex;
    justify-content: space-evenly;
}

.previous-section{
    border-radius: 12px;
    color:white;
    background-color: #046A38;
    align-self: center;
    padding: 8px;
}

.next-section{
    border-radius: 12px;
    color:white;
    background-color: #43b02a;
    align-self: center;
    padding: 8px;
}