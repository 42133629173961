/* textOffCanvas.css */

.mainCanvas {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35rem;
  width:1rem;
  border-radius: 2rem;
  margin-top: 7rem;
 }

 .sumbitBtn{

  background-color: #26890d;
  color:white;
 }
 
 .sumbitBtn:hover{
    background-color: #1e6e09;
    color: black;
 }
 .buttonContainer{
  display: flex;
  justify-content: space-around;
  background-color: red !important;
 }