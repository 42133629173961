.message-inp {
  font-weight: bold;
  font-size: large;
  color: #fff;
  border: none;
  padding:0px 2.25rem;
  margin-bottom: -20px;
}

.send-toDB-btn:hover{
  opacity: 0.8;
}

.timestamp {
  font-size: 0.7em;
  color: #adb5bd;
  align-self: flex-start;
  margin-right: 10px;
}

.clockBtn {
  width: 4.4rem;
  margin-left: 11rem;
  background-color: red;
  color: red;
}

.chat-messages1 {
  background-color: white;
  padding-right: 40px;
  height: 80vh;
  overflow-y: auto;
  display: flex;
}

.chat-container2 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.message1 {
  margin-bottom: 5px;
  padding: 10px;
  padding-top: 0;
  display: flex;
}

.message-label{
  display: inline-block;
  font-weight: bold;
}

.RbtIcnWrapper{
  background-color: #43B02A;
  border-radius: 50%;
  height: 35px !important;
  min-width: 35px !important;
  text-align: center;
  margin-left: 10px;
}

.UsrIcnWrapper{
  background-color: #D0D0CE;
  margin-left: 10px;
  margin-top: 1px;
  border-radius: 50%;
  height: 35px !important;
  min-width: 35px !important;
  text-align: center;
}

.message-wrapper {
  display: flex;
}

.promptContainer {
  display: flex;
  align-items: center;
  width: 80% !important;
  justify-content: center;
  border: 1px solid #BBBCBC !important;
  border-radius: 20px;
  min-height: 45px;
  margin-bottom: 4px;
}


.promptForm {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  width: calc(100% - 50px) !important;
  padding-top: 7px;
  resize: none;
  margin-left: 10px;
  margin-right: 10px;
  overflow-y: auto;
  max-height: calc(5 * 1.5em);
}

.promptForm.form-control{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.promptForm.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.promptForm:hover{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.sendBtn{
  border: none !important;
  outline: none !important;
  width: 42px;
  margin-right: 15px;
  height: 35px;
  background-color: #2C5234 !important;
}

.disabledSendBtn{
  border: none !important;
  outline: none !important;
  width: 42px;
  margin-right: 15px;
  height: 35px;
  background-color: #adb5bd !important;
}

.code-wrapper{
  background-color: #D0D0CE;
  border-radius: 10px;
  width: calc(60vw - 20px);
}

.code-block{
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  padding-bottom: 1px !important;
}

.languageHeader{
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 5px;
  background-color: #53565A;
  color: white;
  font-size: 14px;
}

.codeCopy{
  color: white;
  text-align: end;
  margin-right: 10px;
  margin-top: 4px;
  cursor: pointer;
}

.codeCopy:hover{
  color: #43B02A;
}

.bottomCopy:hover{
  cursor: pointer;
  color: #2C5234;
}
