.contextTitle{
    font-weight: bold;
}

.contextHeader{
    margin-bottom: 20px;
}

.contextItem {
    list-style-type: disc !important;
    margin-bottom: 10px;
    border: none;
  }