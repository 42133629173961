.menu-wrapper {
    width: 90%;
    margin: auto;
}

.assessment-menu-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
}

.menu-item {
    width: 30%;
    margin: 0rem 0.2rem;
    color: #53565A;
    background-color: #BBBCBC;
    border-radius: 10px;
    padding: 0.15rem 0.5rem;
    border-style: solid;
    border-color: #BBBCBC;
    text-align: center;
}

.menu-item:hover {
    cursor: pointer;
}

.menu-item.selected {
    color: black;
    background-color: #86BC25;
    border-color: #86BC25;
    border-style: solid;
}