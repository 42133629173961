.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 174px);
  padding-bottom: 20px;
}

.chat-messages {
  background-color: #43B02A;
  margin-left: -20px !important;
  margin-right: -18px !important;
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid black;
  width: 100%;
  display: flex;
  background-color: white;
}

.sent {
  margin-left: 200px;
  align-self: flex-end;
  justify-content: flex-end;
}

.received {
  margin-right: 200px;
  align-self: flex-start;
  justify-content: flex-start;
}

.message-input-container {
  background-color: #BBBCBC;
  max-height: 83px;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.message-input {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
}

.send-button {
  font-weight: bold;
  font-size: large;
  padding-right: 27px !important;
  padding-left: 27px !important;
  background-color: #26890d;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.messageArea {
  border: 2px solid lightgrey !important;
  resize: none;
}
.messageArea:focus {
  border-color: #26890d !important;
}

.chatHr {
  height: 2px;
  background-color: #BBBCBC;
  opacity: 0.8;
  margin-bottom: 0px;
}

.robotIcon {
  margin-top: 3px;
  padding-top: 2px;
  padding-left: 8px;
  background-color: #D0D0CE;
  border-radius: 99px;
  max-height: 50px;
}

.humanIcon {
  margin-top: 3px;
  padding-top: 2px;
  padding-left: 8px;
  background-color: #86BC25;
  border-radius: 99px;
  max-height: 50px;
}
