.breadcrumb-wrapper {
    display: flex;
}

.breadcrumb-leaf {
    color: #86BC25;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;

}

.breadcrumb-body {
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;

}

.breadcrumb-body:hover{
    cursor: pointer;
}

.breadcrumb-wrapper span {
    color: #808080;
}