.principalTitle{
    color: #43B02A;
    font-weight: bold;
    margin-left: 20px;
    font-size: 17px;
}

.makeFlex{
   display: flex;
   flex-wrap: wrap;
    margin-left: 20px;
}