.infr-ingestion-wrapper .nav-link.active{
    background-color: #86BC25;
}

.infr-ingestion-wrapper .nav-link{
    background-color: #BBBCBC;
    color: #53565A;
}

select{
    padding:0rem 0.5rem;
    border-radius: 15px;
    border-width: 0.15rem;
    border-color:#43B02A;
    background-color:#43B02A;
    color:white
}

select:focus{
    border-color:#2C5234;
}

select option:checked{
    background-color:#2C5234;
}

select option:hover {
    background-color: initial; /* Reset the background color on hover */
    color: initial; /* Reset the text color on hover */
  }
