.soft-obs-wrapper{
    display: flex;
    flex-direction: column;
}

.soft-obs-overview-wrapper{
    display:flex;
}

.tall-rectangle{
    color:white;
    padding:4rem 1rem;
    text-align: center;
    vertical-align: middle;
}

.slider-wrapper{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    min-height: 230px;
    gap:0.5rem;
}