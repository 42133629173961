/* Table.css */
.table-container {
  margin-top: 20px;
  background-color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border: 1px solid #dddddd;
}

tr.selectable:hover{
  background-color: #808080;
  cursor: pointer;
}

tr.selectable:hover td{
  border: 1px solid #808080;
}

th {
  background-color: #F9FAFB;
}