.selected-bar {
    width: 100%;
    font-family: Arial, sans-serif;
    background-color: #F5F5F5;
  }
  
  .title {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #02351C;
    font-size: 24px;
    display: flex;
    }
  
  .options {
    display: flex;
    justify-content: space-evenly;
    background-color: white;
    width:95%;
    padding: 5px;
    border-radius: 5px;
  }
  
  .option {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
   }
  
  .option.selected {
    background-color: #86BC2529; /* Green background for selected */
    color: #86BC25;    
   }
   .option.show {
    background-color: #86BC2529; /* Green background for selected */
    color: #86BC25;    
   }
   .option.hide{
    background-color: #D9D9D9;
   }
  