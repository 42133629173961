.assessment-button{
    background-color: #43b02a;
    border-radius: 20px;
    padding:0.1rem 2rem;
    color:white;
    border-color: #D0D0CE;
    margin:0.25rem 0rem;
    border: 2px solid black !important;
}

.assessment-button:hover{
    opacity: 85%;
}