.documentForm{
    margin-top: 20px;
    margin-left: 200px;
    margin-right: 200px;
}

.formHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generateText{
  margin-right: 10px;
}
  
.docTitle {
    margin-top: 10px;
    font-size: 27px;
    margin-left: 50px;
    width: 600px;
}

.form-control{
  border: 1px solid green !important;
}

.form-control:hover{
  border-color: green;
  box-shadow: 0 0 5px rgba(0, 128, 0, 0.7);
}