 
.robot-icon.right {
  font-size: 2em;
   color: black;
    margin-left:5;
 }
 
 .robot-icon.left {
  font-size: 2em;
   color: black;
    margin-left:17rem;
 }



.popover.right{
  padding: 0.5rem;
  margin-top:1rem;
  margin-left: 2rem;
}
.popover.left{
  padding: 0.5rem;
  margin-top:1rem;
  margin-left: -0rem;
   
}